<template>
  <div class="card-filter-section">
    <div class="d-flex align-items-center flex-wrap justify-content-between">
      <div class="d-flex align-items-center justify-content-start flex-wrap">
        <p
          class="filter-title"
          style="color:#2178fb"
        >
          Filters:
        </p>

        <div class="">
          <custom-dropdown
            v-model="groupId"
            :options="$store.state.app.groupList"
            label="Group"
          />
        </div>
        <div class="">
          <custom-dropdown
            v-model="client_id"
            :options="clientListOption"
            multiple
            label="Client"
          />
        </div>

        <div>
          <custom-dropdown
            v-model="projectIds"
            :options="filterProjectOptions"
            label="Project"
            multiple
          />
        </div>
        <div>
          <custom-dropdown
            v-model="tagList"
            multiple
            :options="$store.state.app.tagList"
            label="Tag"
          />
        </div>
        <div>
          <custom-dropdown
            v-model="isBillable"
            :options="billable_list"
            :search-box="false"
            label="Billable"
          />
        </div>
        <div>
          <custom-dropdown
            v-model="memberList"
            :options="filterMemberOptions"
            multiple
            label="Members"
          />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <VueDatePicker
          :start="start"
          :end="end"
          :active-btn="active"
          @date="$emit('updateDate',$event)"
        />
      </div>
      <div class="d-flex align-items-center">
        <div class="ml-1">
          <button
            class="apply-filter filterRedesign mr-1"
            @click="applyFilter()"
          >
            Apply Filter
          </button>
          <button
            class="apply-filter-clear filterRedesign"
            @click="clearFilter()"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import * as moment from 'moment/moment'
import VueDatePicker from './calender.vue'

export default {
  name: 'TaskFilter',
  components: {
    customDropdown,
    VueDatePicker,
  },
  props: {
    start: {
      type: String,
      required: false,
      default: null,
    },
    end: {
      type: String,
      required: false,
      default: null,
    },
    filter: {
      type: Object,
      required: false,
      default: null,
    },
    active: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      client_id: [],
      memberList: [],
      status: null,
      budget: null,
      user: null,
      projectsList: [],
      projectUsers: [],
      projectIds: this.$route.query.summaryProjects ? typeof this.$route.query.summaryProjects === 'string' ? [this.$route.query.summaryProjects] : this.$route.query.summaryProjects : [],
      tagList: [],
      isBillable: null,
      groupId: null,
      date: null,
    }
  },
  computed: {
    filterProjectOptions() {
      const allProject = []
      this.projectsList.forEach(element => {
        allProject.push({
          value: element.id,
          label: element.name,
          type: element.work_type,
        })
      })

      // Sort the allProject array alphabetically by project name (label)
      allProject.sort((a, b) => a.label.localeCompare(b.label))

      return allProject
    },
    clientListOption() {
      this.$store.state.app.clientList.forEach(element => {
        element.value = element.id
        element.label = element.name
      })

      return this.$store.state.app.clientList
    },
    filterMemberOptions() {
      let users = []
      if (this.projectIds.length) {
        this.projectUsers.forEach(element => {
          users.push({
            value: element.id,
            label: element.full_name,
          })
        })

        // Sort the allProject array alphabetically by project name (label)
        users.sort((a, b) => a.label.localeCompare(b.label))
      } else {
        users = this.$store.state.app.usersList
      }

      return users
    },
  },
  watch: {
    filter() {
      this.filterValueSet()
    },
    projectIds(nv) {
      this.memberList = []
      if (nv && nv.length) {
        this.getProjectUsers()
      }
    },
  },
  mounted() {
    this.filterFunctionCallFromMixin()
    this.date = moment().endOf('month').format('YYYY-MM')
    /* for get project user for first time */
    if (this.filter && this.filter.projectIds && this.filter.projectIds === this.projectIds) {
      this.getProjectUsers()
    }
    this.filterValueSet()
  },
  methods: {
    /* filter value set */
    filterValueSet() {
      this.groupId = this.filter && this.filter.groupId ? this.filter.groupId : this.groupId
      this.projectIds = this.filter && this.filter.projectIds ? this.filter.projectIds : this.projectIds
      this.tagList = this.filter && this.filter.tagList ? this.filter.tagList : this.tagList
      this.isBillable = this.filter && this.filter.isBillable ? this.filter.isBillable : this.isBillable
      this.client_id = this.filter && this.filter.client_id ? this.filter.client_id : this.client_id
      this.memberList = this.filter && this.filter.memberList ? this.filter.memberList : this.memberList
    },
    /*  filter data */
    async filterFunctionCallFromMixin() {
      if (this.$store.state.app.groupList.length === 0) {
        await this.getGroupData()
      }
      if (this.$store.state.app.clientList.length === 0) {
        await this.clientList()
      }

      this.projectsList = await this.projectList()
    },

    applyFilter(id) {
      this.$emit('applyFilter', {
        groupId: this.groupId,
        projectIds: this.projectIds,
        tagList: this.tagList,
        isBillable: this.isBillable,
        client_id: this.client_id,
        clear: !!id,
        memberList: this.memberList,
      })
    },
    clearFilter() {
      if (!this.groupId && this.projectIds == [] && this.tagList == [] && this.client_id == [] && this.isBillable === null) { return }
      this.groupId = null
      this.projectIds = []
      this.tagList = []
      this.isBillable = null
      this.client_id = []
      this.memberList = []
      this.applyFilter('clear')
    },
    /* Reporting And Manage By Users List */
    async getProjectUsers() {
      this.projectUser = []
      const input = {
        project_id: this.projectIds,
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'timesheet-user-list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        data.user.push(this.userInfo)
        this.projectUsers = data.user
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables/_variables.scss";

.card-filter-section {
  background-color: $white;
  margin: 10px 0px 25px 0px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 10px 15px;

  .filter-title {
    color: $logo-color;
    font-size: 2.25 * $rem;
    font-weight: $fw500;
  }

  .selectmember {
    cursor: pointer;
    margin-left: 20px;
    background-color: transparent;
    border-color: transparent;
    color: $logo-color;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: map-get($mediaSizes , "xl")) {
      margin-left: 18px;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      margin-left: 0;
      margin: 7px 0;
    }

    span {
      font-size: 1.75 * $rem;
      font-weight: $fw500;
      text-transform: capitalize;
      line-height: 23px;
    }

    .feather-edit {
      margin-right: 8px;
    }

    .feather-chevron-down {
      margin-left: 8px;
    }

    .badge-pill-filter {
      position: absolute;
      top: -12px;
      right: 13px;
      display: flex;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: rgba(255, 159, 67, 0.7);
    }
  }

  .radio-dropdown {
    position: absolute;
    z-index: 111;
  }

  .apply-filter {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
    padding: 8px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }

  .apply-filter-clear {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    background-color: transparent;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }

  .search-project {
    border-radius: 5px;
    color: $dark-gray;

    &:focus-visible {
      outline: $primary-color;
    }

    .form-control {
      border-color: $input-outline;
      padding: 6px 0 6px 20px;
      color: $logo-color !important;
      height: 33px;

      &::placeholder {
        color: $dark-gray;
        font-size: 1.5 * $rem;
        font-weight: $fw500;
      }
    }
  }
}</style>
